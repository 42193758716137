import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private apiService = inject(ApiService);

  getReportList(page: number) {
    return this.apiService.getRefusalSummaryList(page);
  }
}
