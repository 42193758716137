import { Component, inject } from '@angular/core';
import { AnnounceService } from '../../../services/announce.service';
import { AnnounceLog, Pagination } from '../../../lib/gen/openapi';
import { GetAnnounceListResponseData } from '../../../lib/gen/openapi/model/get-announce-list-response-data';
import { RouterLink } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-announce-list',
  standalone: true,
  imports: [RouterLink, NgbPaginationModule, DatePipe],
  templateUrl: './announce-list.component.html',
  styleUrl: './announce-list.component.scss',
})
export class AnnounceListComponent {
  private announceService = inject(AnnounceService);

  // ページネーション
  pagination?: Pagination;
  currentPage = 0;
  total = 0;
  // リストデータ
  listData: GetAnnounceListResponseData[] = [];

  /**
   * 更新
   * @param pageNumber
   */
  onPageChange(pageNumber: number) {
    this.update(pageNumber);
  }

  /**
   * 更新
   */
  private update(pageNumber: number) {
    this.announceService.getAnnounceList(pageNumber).then((res) => {
      if (res.result?.data != null && res.result?.pagination != null) {
        this.pagination = res.result.pagination;
        this.currentPage = res.result.pagination.currentPage ?? 0;
        this.total = res.result.pagination.total ?? 0;
        this.listData = res.result.data;
      }
    });
  }
}
