<h1 class="text-primary mb-4">違反報告</h1>

<div class="card">
  <div class="card-body p-4">

    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <div>{{ pagination?.total }}</div>
        <div class="ps-1 pe-3">件中</div>
        <div>{{ pagination?.from }}</div>
        <div class="px-1">〜</div>
        <div>{{ pagination?.to }}</div>
        <div class="ps-1">件表示</div>
      </div>
    </div>

    <div class="common-list-wrapper">
      <table class="table common-list-table">
        <thead>
          <tr>
            <th>種類</th>
            <th>物件ID-ニックネーム</th>
            <th class="text-center">報告数</th>
            <th class="text-center">未確認の報告</th>
            <th>内容</th>
          </tr>
        </thead>
        <tbody>

          @for (item of resData; track $index) {
            <tr>
              <td>
                <div class="common-list-report-type">
                  @if (item.comment_info === null) {
                    <div class="material-symbols-rounded common-list-report-type__icon" aria-hidden="true">other_houses</div>
                    <div class="common-list-report-type__text">物件</div>
                  }
                  @else {
                    <div class="material-symbols-rounded common-list-report-type__icon" aria-hidden="true">chat</div>
                    <div class="common-list-report-type__text">コメント</div>
                  }
                </div>
              </td>
              <td>
                <a [routerLink]="['/posts', item.publish_info?.publish_id]">{{ item.publish_info?.publish_id }}</a>
                @if (item.comment_info !== null) {
                  - <a [routerLink]="['/user', item.comment_info?.owner?.user?.user_id]">{{ item.comment_info?.owner?.user?.nickname }}</a>
                  @if (item.comment_info?.owner?.is_ban) {
                    <div class="common-list-suspended">
                      <div class="material-symbols-rounded common-list-suspended__icon" aria-hidden="true">warning</div>
                      <div class="common-list-suspended__text">凍結中</div>
                    </div>
                  }
                }
              </td>
              <td class="text-center">{{ item.refusal_count }}</td>
              <td class="text-center">
                @if (item.has_unread_refusal) {
                  <span class="badge bg-danger">あり</span>
                }
                @else {
                  <span class="badge bg-secondary">なし</span>
                }
              </td>
              <td>
                {{ item.reason_text_list }}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <nav class="pt-2">
      <ngb-pagination [collectionSize]="total" [(page)]="currentPage" [maxSize]="5" aria-label="Default pagination" (pageChange)="onPageChange($event)">
        <ng-template ngbPaginationPrevious>前へ</ng-template>
        <ng-template ngbPaginationNext>次へ</ng-template>
      </ngb-pagination>
    </nav>

  </div>
</div>

