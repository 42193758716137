{
    "status": 200,
    "error_message": "",
    "result": {
        "data": [
            {
                "announce_log": {
                    "announce_log_id": 4000,
                    "yyyymmdd": "2024-04-03 09:28:40",
                    "content": {
                        "announce_id": 1001442,
                        "disp_text": "物件ID 12093487 の担当エージェントが24時間決まっていません。",
                        "link": "posts/12093487"
                    }
                }
            }
        ],
        "pagination": {
            "currentPage": 1,
            "from": 1,
            "lastPage": 1,
            "perPage": 10,
            "to": 1,
            "total": 1
        }
    }
}