<h1 class="text-primary mb-4">お知らせ</h1>

<div class="card">
  <div class="card-body p-4">

    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <div>{{ pagination?.total }}</div>
        <div class="ps-1 pe-3">件中</div>
        <div>{{ pagination?.from }}</div>
        <div class="px-1">〜</div>
        <div>{{ pagination?.to }}</div>
        <div class="ps-1">件表示</div>
      </div>
    </div>

    <div class="common-list-wrapper">
      <table class="table common-list-table">
        <thead>
          <tr>
            <th>日付</th>
            <th>内容</th>
          </tr>
        </thead>
        <tbody>
          @for (item of listData; track $index) {
            <tr>
              <td>{{ item?.announce_log?.yyyymmdd| date:'yyyy/M/d' }}</td>
              <td>
                @if (item?.announce_log?.content?.link?.startsWith("posts/")) {
                  @let link = item?.announce_log?.content?.link?.split('/') ?? [];
                  <a href="{{ item?.announce_log?.content?.link }}">物件ID&nbsp;{{ link[1] }}</a>&nbsp;の担当エージェントが24時間決まっていません。
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <nav class="pt-2">
      <ngb-pagination [collectionSize]="total" [(page)]="currentPage" [maxSize]="5" aria-label="Default pagination" (pageChange)="onPageChange($event)">
        <ng-template ngbPaginationPrevious>前へ</ng-template>
        <ng-template ngbPaginationNext>次へ</ng-template>
      </ngb-pagination>
    </nav>

  </div>
</div>
