{
  "_comment": "ユーザーリスト",
  "result": {
    "user_list": [
      {
        "user": {
          "user_id": 10000000001,
          "nickname": "猫一筋",
          "email": "arata.s.saito@gmail.com",
          "sei": "斉藤",
          "mei": "新"
        },
        "identification_status_info": {
          "process_status": 1
        },
        "posts_count": 1
      },
      {
        "user": {
          "user_id": 10000000002,
          "nickname": "ハードボイルド",
          "email": "t.asanishi@yahoo.co.jp",
          "sei": "朝西",
          "mei": "鉄平"
        },
        "identification_status_info": {
          "process_status": 0
        },
        "posts_count": 0
      },
      {
        "user": {
          "user_id": 10000000003,
          "nickname": "しのぶ",
          "email": "tsushima8790@hotmail.com",
          "sei": "津島",
          "mei": "忍"
        },
        "identification_status_info": {
          "process_status": 1
        },
        "posts_count": 0
      },
      {
        "user": {
          "user_id": 10000000004,
          "nickname": "シティ",
          "email": "yukito.ono@propre-japan.com",
          "sei": "小野",
          "mei": "由紀人"
        },
        "identification_status_info": {
          "process_status": 1
        },
        "posts_count": 2
      },
      {
        "user": {
          "user_id": 10000000005,
          "nickname": "釣り好きさん",
          "email": "sou1ro.maehara@gmail.com",
          "sei": "前原",
          "mei": "宗一郎"
        },
        "identification_status_info": {
          "process_status": 1
        },
        "posts_count": 0
      }
    ],
    "pagination": {
      "currentPage": 2,
      "from": 11,
      "lastPage": 3,
      "perPage": 10,
      "to": 20,
      "total": 100
  }
  },
  "status": 200,
  "error_message": ""
}