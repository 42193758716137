import { AsyncPipe, NgIf } from '@angular/common';
import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { NgbNav, NgbNavLink, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    RouterModule,
    RouterOutlet,
    NgIf,
    AsyncPipe,
    NgbNavModule,
    HeaderComponent,
    FooterComponent,
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent implements OnInit {
  @ViewChild(NgbNav, { static: true })
  ngbNav!: NgbNav;

  private router = inject(Router);

  url$: Observable<string> = new Observable<string>();

  activeNav = signal('dashboard');

  links = [
    { title: 'トップページ', fragment: 'dashboard', hide: false },
    { title: 'お知らせ', fragment: 'announce', hide: false },
    { title: 'ユーザー管理', fragment: 'user', hide: false },
    { title: '違反報告', fragment: 'report', hide: false },
    { title: '物件管理', fragment: 'publish-info', hide: true },
  ];

  constructor(public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.updateNav();

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.updateNav();
      }
    });
  }

  private updateNav() {
    const urlPath = this.route?.snapshot?.firstChild?.url[0].path;
    if (urlPath != null) {
      this.activeNav.set(urlPath);
      // select/set active tab
      this.ngbNav?.select(urlPath);
    }
  }
}
