<div class="container-fluid px-0">
  <div class="row">

    <div class="col-4">
      <div class="card">
        <div class="card-body px-4">
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="text-primary mb-0">お知らせ</h2>
            <a [routerLink]="['/announce']" class="d-block fs-6">すべて見る</a>
          </div>
          <ul class="dashboard-list">
            @for (item of resAnnounce; track $index) {
              <li>
                <small>{{ item?.announce_log?.yyyymmdd| date:'yyyy/M/d' }}</small>
                @if (item?.announce_log?.content?.link?.startsWith("posts/")) {
                  @let link = item?.announce_log?.content?.link?.split('/') ?? [];
                  <a href="{{ item?.announce_log?.content?.link }}">物件ID&nbsp;{{ link[1] }}</a>&nbsp;の担当エージェントが24時間決まっていません。
                }
              </li>
            }
          </ul>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="card">
        <div class="card-body px-4">
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="text-primary mb-0">違反報告</h2>
            <a [routerLink]="['/report']" class="d-block fs-6">すべて見る</a>
          </div>
          <ul class="dashboard-list">
            @for (item of resRefusal; track $index) {
              <li>
                <small>{{ item.latest | date:'yyyy/M/d' }}</small>
                @if (item.comment_info === null) {
                  <a [routerLink]="['/posts', item.publish_info?.publish_id]">物件ID&nbsp;{{ item.publish_info?.publish_id }}</a>&nbsp;に違反報告があります。対応してください。
                }
                @else {
                  <a [routerLink]="['/posts', item.publish_info?.publish_id]">物件ID&nbsp;{{ item.publish_info?.publish_id }}&nbsp;のコメント</a>&nbsp;に違反報告があります。対応してください。
                }
              </li>
            }
          </ul>
        </div>
      </div>
    </div>

  </div>
</div>
