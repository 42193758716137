<div class="modal-body">
  <div class="p-3 text-center fw-bold fs-3">
    {{ message }}
  </div>
  <div class="text-center pt-2 pb-3">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">
      {{ closeBtnText }}
    </button>
  </div>
</div>
