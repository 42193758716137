import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiService = inject(ApiService);

  getUserList(page: number, term?: string) {
    return this.apiService.getUserList(page, term);
  }

  getDetail(userId: number) {
    return this.apiService.getUserDetail(userId);
  }

  ban(userId: number) {
    return this.apiService.banUser(userId);
  }

  unBan(userId: number) {
    return this.apiService.unBanUser(userId);
  }
}
