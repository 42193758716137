{
    "status": 200,
    "error_message": "",
    "result": {
        "refusal_info_list": [
            {
                "refusal_info": {
                    "reason_type": "1",
                    "reason_cd": 100,
                    "reason_text": "実物の画像がない",
                    "other_reason": null,
                    "status": 0,
                    "reported_at": "2024-01-01T00:00:00.000000Z"
                },
                "publish_info": {
                    "publish_id": 596
                },
                "owner": {
                    "user": {
                        "user_id": 10000000027,
                        "agent_flg": "0",
                        "nickname": "yuki04-----1wtpmjgda@ptw",
                        "sei": "川口",
                        "mei": "由紀子",
                        "email": "yukiko.kawaguchi+4@propre-japan.com",
                        "tel": "0467111111",
                        "intro": "変更確認てすと",
                        "avatar_image_url": "https://fufu-dev.s3.ap-northeast-1.amazonaws.com/66876c199782f_1745.bin"
                    },
                    "identification_status_info": {
                        "process_status": 2,
                        "comment": null
                    },
                    "posts_count": 30,
                    "is_ban": false
                },
                "refusal_count": 1,
                "has_unread_refusal": true,
                "comment_info": {
                    "comment_id": 372,
                    "publish_id": 596,
                    "user_id": 10000000011,
                    "agent_id": null,
                    "registration_type": 0,
                    "comment_type": 1,
                    "contents": "内覧希望です。プロフィールから連絡先を確認してください",
                    "post_date": "2024-09-17 06:36:12",
                    "owner": {
                        "user": {
                            "user_id": 10000000011,
                            "agent_flg": "0",
                            "nickname": "yuki01",
                            "sei": "川口",
                            "mei": "由紀子１",
                            "email": "yukiko.kawaguchi+1@propre-japan.com",
                            "tel": "0467111111",
                            "intro": "",
                            "avatar_image_url": null
                        },
                        "identification_status_info": {
                            "process_status": 2,
                            "comment": null
                        },
                        "posts_count": 1,
                        "is_ban": false
                    },
                    "refusal_count": 1,
                    "un_read_refusal_count": 1
                },
                "reported_at": "2024-01-01T00:00:00.000000Z"
            }
        ]
    }
}