import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss',
})
export class ReportComponent {}
