import { Component, Input } from '@angular/core';

import { DatePipe, DecimalPipe } from '@angular/common';
import { PublishAdditionalInfo } from '../../../../lib/gen/openapi';

@Component({
  selector: 'app-publish-info-detail-investment-property',
  standalone: true,
  imports: [DecimalPipe, DatePipe],
  templateUrl: './publish-info-detail-investment-property.component.html',
  styleUrl: '../publish-info-detail.component.scss',
})
export class PublishInfoDetailInvestmentPropertyComponent {
  @Input()
  additionalInfo?: PublishAdditionalInfo;
}
