<div class="row">
  <div class="col-4">
    <table class="table table-bordered detail-table-sm">
      <tbody>
        <tr>
          <th>権利</th>
          <td>{{ additionalInfo?.right ?? '-' }}</td>
        </tr>
        <tr>
          <th>引渡し時期</th>
          <td>{{ additionalInfo?.delivery_time ?? '-' }}</td>
        </tr>
        <tr>
          <th>取引態様</th>
          <td>{{ additionalInfo?.transactions_conditions ?? '-' }}</td>
        </tr>
        <tr>
          <th>現況</th>
          <td>{{ additionalInfo?.current_conditions ?? '-' }}</td>
        </tr>
        <tr>
          <th>地目</th>
          <td>{{ additionalInfo?.land_category ?? '-' }}</td>
        </tr>
        <tr>
          <th>都市計画</th>
          <td>{{ additionalInfo?.city_planning ?? '-' }}</td>
        </tr>
        <tr>
          <th>用途地域</th>
          <td>{{ additionalInfo?.use_districts ?? '-' }}</td>
        </tr>
        <tr>
          <th>建蔽率</th>
          <td>{{ additionalInfo?.site_coverage ? additionalInfo?.site_coverage + '%' : '-' }}</td>
        </tr>
        <tr>
          <th>容積率</th>
          <td>{{ additionalInfo?.floor_area_ratio ? additionalInfo?.floor_area_ratio + '%' : '-' }}</td>
        </tr>
        <tr>
          <th>接道状況</th>
          <td>{{ additionalInfo?.adjacent_road_situation ? additionalInfo?.adjacent_road_situation + '側' : '-' }}</td>
        </tr>
        <tr>
          <th>幅員</th>
          <td>{{ additionalInfo?.road_width ? additionalInfo?.road_width + 'm' : '-' }}</td>
        </tr>
        <tr>
          <th>接道長さ</th>
          <td>{{ additionalInfo?.adjacent_road_length ? additionalInfo?.adjacent_road_length + 'm' : '-' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-4">
    <table class="table table-bordered detail-table-sm">
      <tbody>
        <tr>
          <th>地盤の沈下、軟弱</th>
          <td>
            @if (additionalInfo?.has_ground_situation != null ) {
              {{ additionalInfo?.has_ground_situation }}<br />
              {{ additionalInfo?.ground_situation_content ?? '-' }}
            }
            @else {
              -
            }
          </td>
        </tr>
        <tr>
          <th>土壌汚染</th>
          <td>
            {{ additionalInfo?.has_soil_contamination ?? '-' }}
          </td>
        </tr>
        <tr>
          <th>過去に店舗工場等、住宅以外での使用履歴</th>
          <td>
            @if (additionalInfo?.has_usage_history_for_purposes_other_than_residences != null ) {
              {{ additionalInfo?.has_usage_history_for_purposes_other_than_residences }}<br />
              {{ additionalInfo?.usage_history_for_purposes_other_than_residences_content ?? '-' }}
            }
            @else {
              -
            }
          </td>
        </tr>
        <tr>
          <th>地中埋設物</th>
          <td>
            @if (additionalInfo?.has_underground_object != null ) {
              {{ additionalInfo?.has_underground_object }}<br />
              {{ additionalInfo?.underground_object_content ?? '-' }}
            }
            @else {
              -
            }
          </td>
        </tr>
        <tr>
          <th>過去に起きた<br />事件事故</th>
          <td>
            @if (additionalInfo?.has_incident_accident != null ) {
              {{ additionalInfo?.has_incident_accident }}<br />
              {{ additionalInfo?.incident_accident_content ?? '-' }}
            }
            @else {
              -
            }
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
