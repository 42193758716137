import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private router = inject(Router);
  private tokenExtractor = inject(HttpXsrfTokenExtractor);

  private readonly xsrfHeaderName = 'X-XSRF-TOKEN';

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Accept': 'application/json',
      },
      withCredentials: true,
    });

    if (!request.headers.has(this.xsrfHeaderName)) {
      // We have seen header missing in prod for approx 10% traffic, so adding non angular way of retrieving cookie if angular failed for some reason.
      const token = this.tokenExtractor.getToken();
      if (token) {
        request = request.clone({ headers: request.headers.set(this.xsrfHeaderName, token) });
      } else if (request.method === 'POST' && !request.url.endsWith('bootstrap')) {
        // Exclude GET and /ws/bootstrap as it issues xsrf cookie
        console.error('Missing xsrf cookie');
      }
    }

    let token = localStorage.getItem('access_token');
    if (token) {
      request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          /**
           * ログイン画面へのリダイレクトや
           * 「セッションが切れました」というモーダルの表示などを行う
           */
          this.router.navigate(['login']);
        }
        throw new Error(error.message);
      })
    );
  }
}
