import { Component, inject, Input, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PublishInfoService } from '../../services/publish-info.service';
import { GetPublishInfoRefusalListResponseData, OfferInfo } from '../../lib/gen/openapi';
import { DatePipe } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-report-confirm-modal',
  standalone: true,
  imports: [DatePipe, FormsModule, RouterLink],
  templateUrl: './report-confirm-modal.component.html',
  styleUrl: './report-confirm-modal.component.scss',
})
export class ReportConfirmModalComponent {
  private router = inject(Router);
  activeModal = inject(NgbActiveModal);
  private publishInfoService = inject(PublishInfoService);

  @Input() publishId?: number;
  @Input() commentId?: number;

  radioList: { refusalId: number; init: number; selected: number }[] = [];
  refusalList?: GetPublishInfoRefusalListResponseData[];

  public onLoadData() {
    if (this.publishId != null) {
      console.debug({
        publishId: this.publishId,
        commentId: this.commentId,
      });
      if (this.commentId != null) {
        // コメント
        this.publishInfoService.getRefusalInfoListOfComment(this.commentId).then((res) => {
          this.refusalList = res.result?.refusal_info_list;
          this.radioList = [];
          this.refusalList?.forEach((value, index) => {
            if (value.refusal_info?.refusal_id != null) {
              this.radioList[index] = {
                refusalId: value.refusal_info?.refusal_id,
                init: value.refusal_info?.status ?? 0,
                selected: value.refusal_info?.status ?? 0,
              };
            }
          });
        });
      } else {
        // 物件
        this.publishInfoService.getRefusalList(this.publishId).then((res) => {
          this.refusalList = res.result?.refusal_info_list;
          this.radioList = [];
          this.refusalList?.forEach((value, index) => {
            if (value.refusal_info?.refusal_id != null) {
              this.radioList[index] = {
                refusalId: value.refusal_info?.refusal_id,
                init: value.refusal_info?.status ?? 0,
                selected: value.refusal_info?.status ?? 0,
              };
            }
          });
        });
      }
    }
  }

  async onSubmit() {
    for await (const radio of this.radioList) {
      if (radio.init != radio.selected) {
        await this.publishInfoService.updateRefusalInfo(radio.refusalId, {
          status: radio.selected,
        });
      }
    }
    this.activeModal.close('保存する');
  }

  public onClickRepoter(user_id?: any) {
    this.router.navigate(['/user', user_id]);
    this.activeModal.close();
  }
}
