import { Component, inject, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { GetAnnounceListResponseData, GetRefusalInfoListResponseData } from '../../lib/gen/openapi';
import { RouterLink } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [RouterLink, DatePipe],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit {
  private dashboardService = inject(DashboardService);

  resRefusal: GetRefusalInfoListResponseData[] = [];
  resAnnounce: GetAnnounceListResponseData[] = [];

  ngOnInit(): void {
    this.dashboardService.getRecentRefusalList().then((res) => {
      if (res.result?.refusal_summary_list != null) {
        this.resRefusal = res.result.refusal_summary_list;
      }
    });

    this.dashboardService.getRecentAnnounceList().then((res) => {
      if (res.result?.data != null) {
        this.resAnnounce = res.result.data;
      }
    });
  }
}
