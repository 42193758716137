{
  "_comment": "ユーザー詳細",
  "result": {
    "user": {
      "user_id": 10000000005,
      "nickname": "猫好き",
      "email": "arata.s.saito@gmail.com",
      "sei": "斉藤",
      "mei": "新",
      "zipcode": "5990000",
      "address": "大阪府堺市東区南町1-9-3",
      "avatar_image_url": "dummy/user1.jpg",
      "tel": "080-8766-2726",
      "intro": "関西を中心にDIYした物件を出品しています。また、DIYできる物件を探しています。"

    },
    "identification_status_info": {
      "process_status": 2,
      "comment": "免許証"
    },
    "posts_count": 0,
    "is_ban": true
  },
  "status": 200,
  "error_message": ""
}
