{
    "status": 200,
    "error_message": "",
    "result": {
        "refusal_info_list": [
            {
                "refusal_info": {
                    "refusal_id": 24,
                    "reason_type": "1",
                    "reason_cd": null,
                    "reason_text": "その他",
                    "other_reason": "テストです",
                    "status": 0,
                    "reported_at": "2024-01-01T00:00:00.000000Z"
                },
                "publish_info": {
                    "publish_id": 515
                },
                "owner": {
                    "user": {
                        "user_id": 10000000107,
                        "agent_flg": "0",
                        "nickname": "user202404010736543039",
                        "sei": "片岡",
                        "mei": "壮磨",
                        "email": "soma.kataoka+31@propre-japan.com",
                        "tel": "00000000000",
                        "intro": null,
                        "avatar_image_url": null
                    },
                    "identification_status_info": {
                        "process_status": 2,
                        "comment": null
                    },
                    "posts_count": 20,
                    "is_ban": false
                },
                "refusal_count": 4,
                "has_unread_refusal": true,
                "comment_info": null,
                "reported_at": "2024-01-01T00:00:00.000000Z"
            },
            {
                "refusal_info": {
                    "refusal_id": 25,
                    "reason_type": "1",
                    "reason_cd": null,
                    "reason_text": "その他",
                    "other_reason": "テストです",
                    "status": 0,
                    "reported_at": "2024-01-01T00:00:00.000000Z"
                },
                "publish_info": {
                    "publish_id": 515
                },
                "owner": {
                    "user": {
                        "user_id": 10000000107,
                        "agent_flg": "0",
                        "nickname": "user202404010736543039",
                        "sei": "片岡",
                        "mei": "壮磨",
                        "email": "soma.kataoka+31@propre-japan.com",
                        "tel": "00000000000",
                        "intro": null,
                        "avatar_image_url": null
                    },
                    "identification_status_info": {
                        "process_status": 2,
                        "comment": null
                    },
                    "posts_count": 20,
                    "is_ban": false
                },
                "refusal_count": 4,
                "has_unread_refusal": true,
                "comment_info": null
            }
        ]
    }
}