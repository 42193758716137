import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AnnounceService {
  private apiService = inject(ApiService);

  getAnnounceList(page: number) {
    return this.apiService.getAnnounceList(page);
  }
}
