
<div class="login-image-wrapper">
  <img src="logo.svg" alt="FUFU" class="login-image">
</div>

<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="card login-card">
    <div class="card-body login-card-body">
      <div>
        <label for="id" class="form-label">ログインID</label>
        <input type="text" class="form-control" id="id" placeholder="ログインID" formControlName="loginId">
      </div>
      <div class="mt-3">
        <label for="password" class="form-label">パスワード</label>
        <input type="password" class="form-control" id="password" placeholder="パスワード" formControlName="password">
      </div>
      <div class="d-grid login-button-wrapper">
        <button type="submit" class="btn btn-primary">ログイン</button>
      </div>
    </div>
  </div>
</form>
