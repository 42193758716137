import { Component, HostBinding } from '@angular/core';
import packageInfo from './../../../../../../../package.json';
import { compareAsc, format } from 'date-fns';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  @HostBinding('class') classes: string = 'main-footer';
  public appVersion = packageInfo.version;
  public currentYear: string = format(new Date(), 'yyyy');
}
