import { Routes } from '@angular/router';
import { MainComponent } from './modules/main/main.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AnnounceListComponent } from './pages/announce/announce-list/announce-list.component';
import { ReportListComponent } from './pages/report/report-list/report-list.component';
import { PublishInfoDetailComponent } from './pages/publish-info/publish-info-detail/publish-info-detail.component';
import { UserListComponent } from './pages/user/user-list/user-list.component';
import { UserComponent } from './pages/user/user.component';
import { UserDetailComponent } from './pages/user/user-detail/user-detail.component';
import { AnnounceComponent } from './pages/announce/announce.component';
import { ReportComponent } from './pages/report/report.component';
import { PublishInfoComponent } from './pages/publish-info/publish-info.component';
import { LoginComponent } from './modules/login/login.component';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'announce',
        component: AnnounceComponent,
        children: [
          {
            path: '',
            component: AnnounceListComponent,
          },
        ],
      },
      {
        path: 'user',
        component: UserComponent,
        children: [
          {
            path: '',
            component: UserListComponent,
          },
          {
            path: ':id',
            component: UserDetailComponent,
          },
        ],
      },
      {
        path: 'report',
        component: ReportComponent,
        children: [
          {
            path: '',
            component: ReportListComponent,
          },
        ],
      },
      {
        path: 'posts',
        component: PublishInfoComponent,
        children: [
          {
            path: ':id',
            component: PublishInfoDetailComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  { path: '**', redirectTo: '' },
];
