import { Component, inject } from '@angular/core';
import { LoginService } from '../../services/login.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  private loginService = inject(LoginService);
  private router = inject(Router);

  loginForm: FormGroup = new FormGroup({
    loginId: new FormControl<string>('', Validators.required),
    password: new FormControl<string>('', Validators.required),
  });

  async onSubmit() {
    console.debug(this.loginForm.value);
    const loginId = this.loginForm.get('loginId')?.value;
    const password = this.loginForm.get('password')?.value;
    if (loginId != null && password != null) {
      try {
        const res = await this.loginService.login(loginId, password);
        if (res.result?.token) {
          this.router.navigate(['dashboard']);
        } else {
          throw new Error('ログイン失敗');
        }
      } catch (error) {
        alert('ログイン失敗');
      }
    }
  }
}
