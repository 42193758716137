import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { RefusalInfo } from '../lib/gen/openapi';

@Injectable({
  providedIn: 'root',
})
export class PublishInfoService {
  private apiService = inject(ApiService);

  getDetail(publishId: number) {
    return this.apiService.getPublishInfo(publishId);
  }

  getCommentList(publishId: number) {
    return this.apiService.getCommentListByPublishId(publishId);
  }

  getOfferList(publishId: number) {
    return this.apiService.getOfferListByPublishId(publishId);
  }

  getRefusalList(publishId: number) {
    return this.apiService.getRefusalListByPublishId(publishId);
  }

  updateRefusalInfo(id: number, refusalInfo: RefusalInfo) {
    return this.apiService.updateRefusalInfo(id, refusalInfo);
  }

  getRefusalInfoListOfComment(commentId: number) {
    return this.apiService.getCommentRefusalInfoList(commentId);
  }

  deletePublishInfo(publishId: number) {
    return this.apiService.deletePublishInfo(publishId);
  }
}
