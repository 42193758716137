{
    "status": 200,
    "error_message": "",
    "result": {
      "refusal_summary_list": [
        {
          "publish_info": {
            "publish_id": 12093487
          },
          "comment_info": null,
          "refusal_count": 1,
          "has_unread_refusal": true,
          "reason_text_list": "他サービス誘導・直接取引",
          "latest": "2024-09-13 05:23:26"
        },
        {
          "publish_info": {
            "publish_id": 20137496
          },
          "comment_info": {
            "comment_id": 1,
            "owner": {
              "user": {
                "user_id": 1,
                "nickname": "DIYドロヌマン",
                "sei": "川口",
                "mei": "由紀子",
                "email": "yukiko.kawaguchi@propre-japan.com",
                "tel": "123456789",
                "intro": "",
                "avatar_image_url": "https://fufu-dev.s3.ap-northeast-1.amazonaws.com/65d5a9705ba8a_3086.jpg"
              },
              "identification_status_info": {
                "process_status": 2,
                "comment": null
              },
              "posts_count": 8,
              "is_ban": false
            }
          },
          "refusal_count": 1,
          "has_unread_refusal": false,
          "reason_text_list": "スパム、宣伝目的である",
          "latest": "2024-09-13 05:23:26"
        },
        {
          "publish_info": {
            "publish_id": 20137496
          },
          "comment_info": null,
          "refusal_count": 3,
          "has_unread_refusal": true,
          "reason_text_list": "スパム、宣伝目的である, 誹謗中傷, その他理由",
          "latest": "2024-09-13 05:23:26"
        },
        {
          "publish_info": {
            "publish_id": 10294858
          },
          "comment_info": null,
          "refusal_count": 1,
          "has_unread_refusal": true,
          "reason_text_list": "その他理由",
          "latest": "2024-09-13 05:23:26"
        },
        {
          "publish_info": {
            "publish_id": 10294858
          },
          "comment_info": {
            "comment_id": 1,
            "owner": {
              "user": {
                "user_id": 1,
                "nickname": "釣り好きさん",
                "sei": "川口",
                "mei": "由紀子",
                "email": "yukiko.kawaguchi@propre-japan.com",
                "tel": "123456789",
                "intro": "",
                "avatar_image_url": "https://fufu-dev.s3.ap-northeast-1.amazonaws.com/65d5a9705ba8a_3086.jpg"
              },
              "identification_status_info": {
                "process_status": 2,
                "comment": null
              },
              "posts_count": 8,
              "is_ban": true
            }
          },
          "refusal_count": 2,
          "has_unread_refusal": true,
          "reason_text_list": "公序良俗に反している, 犯罪行為である、又はそれを助長している",
          "latest": "2024-09-13 05:23:26"
        }
      ]
    }
  }
  