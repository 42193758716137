import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-complete-modal',
  standalone: true,
  imports: [],
  templateUrl: './complete-modal.component.html',
  styleUrl: './complete-modal.component.scss',
})
export class CompleteModalComponent {
  activeModal = inject(NgbActiveModal);

  @Input() message?: string;
  @Input() closeBtnText?: string;
}
