{
    "status": 200,
    "error_message": "",
    "result": {
        "comments": [
            {
                "comment_id": 382,
                "publish_id": 596,
                "user_id": 10000000162,
                "agent_id": null,
                "registration_type": 0,
                "comment_type": 1,
                "contents": "内覧希望です。プロフィールから連絡先を確認してください",
                "post_date": "2024-09-18 02:53:47",
                "owner": {
                    "user": {
                        "user_id": 10000000162,
                        "agent_flg": "0",
                        "nickname": "yamada101",
                        "sei": "山田",
                        "mei": "太郎",
                        "email": "yamada+101@qox.jp",
                        "tel": "08011110001",
                        "intro": null,
                        "avatar_image_url": "https://fufu-dev.s3.ap-northeast-1.amazonaws.com/66d7c34e884c5_7318.bin"
                    },
                    "identification_status_info": {
                        "process_status": 2,
                        "comment": null
                    },
                    "posts_count": 21,
                    "is_ban": false
                },
                "refusal_count": 0,
                "un_read_refusal_count": 0
            },
            {
                "comment_id": 372,
                "publish_id": 596,
                "user_id": 10000000011,
                "agent_id": null,
                "registration_type": 0,
                "comment_type": 1,
                "contents": "内覧希望です。プロフィールから連絡先を確認してください",
                "post_date": "2024-09-17 06:36:12",
                "owner": {
                    "user": {
                        "user_id": 10000000011,
                        "agent_flg": "0",
                        "nickname": "yuki01",
                        "sei": "川口",
                        "mei": "由紀子１",
                        "email": "yukiko.kawaguchi+1@propre-japan.com",
                        "tel": "0467111111",
                        "intro": "",
                        "avatar_image_url": null
                    },
                    "identification_status_info": {
                        "process_status": 2,
                        "comment": null
                    },
                    "posts_count": 1,
                    "is_ban": false
                },
                "refusal_count": 1,
                "un_read_refusal_count": 1
            },
            {
                "comment_id": 371,
                "publish_id": 596,
                "user_id": 10000000062,
                "agent_id": null,
                "registration_type": 0,
                "comment_type": 1,
                "contents": "内覧希望です。プロフィールから連絡先を確認してください",
                "post_date": "2024-09-17 06:32:00",
                "owner": {
                    "user": {
                        "user_id": 10000000062,
                        "agent_flg": "1",
                        "nickname": "yuki-test05",
                        "sei": "y",
                        "mei": "i",
                        "email": "yukiko.kawaguchi+5@propre-japan.com",
                        "tel": "123555555",
                        "intro": "てすと\nてすと\nてすと",
                        "avatar_image_url": "https://fufu-dev.s3.ap-northeast-1.amazonaws.com/65f7f25f8f061_5448.png"
                    },
                    "identification_status_info": {
                        "process_status": 2,
                        "comment": null
                    },
                    "posts_count": 0,
                    "is_ban": false
                },
                "refusal_count": 0,
                "un_read_refusal_count": 0
            },
            {
                "comment_id": 370,
                "publish_id": 596,
                "user_id": 1,
                "agent_id": null,
                "registration_type": 0,
                "comment_type": 1,
                "contents": "内覧希望です。プロフィールから連絡先を確認してください",
                "post_date": "2024-09-17 06:12:23",
                "owner": {
                    "user": {
                        "user_id": 1,
                        "agent_flg": "0",
                        "nickname": "yuki_test",
                        "sei": "川口",
                        "mei": "由紀子",
                        "email": "yukiko.kawaguchi@propre-japan.com",
                        "tel": "123456789",
                        "intro": "",
                        "avatar_image_url": "https://fufu-dev.s3.ap-northeast-1.amazonaws.com/65d5a9705ba8a_3086.jpg"
                    },
                    "identification_status_info": {
                        "process_status": 2,
                        "comment": null
                    },
                    "posts_count": 8,
                    "is_ban": true
                },
                "refusal_count": 0,
                "un_read_refusal_count": 0
            }
        ]
    }
}