<div class="modal-header">
  <h1 class="modal-title mb-0">違反報告</h1>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<form #f="ngForm">
  <div class="modal-body pb-0">
      @for (item of refusalList; track $index) {
        <div class="d-flex mb-4">
          <div class="user-icon-wrapper is-small">
            <img src="{{ item.refusal_info?.reporter?.user?.avatar_image_url }}" alt="プロフィール画像" class="user-icon-image is-small">
            @if (item.refusal_info?.reporter?.identification_status_info?.process_status === 2) {
              <img src="yellow-check-circle.png" alt="本人確認済み" class="user-verified-image">
            }
          </div>
          <div class="ps-3">
            <div>
              <a (click)="onClickRepoter(item?.refusal_info?.reporter?.user?.user_id)" class="fw-bold">{{ item.refusal_info?.reporter?.user?.nickname }}</a>
              <small class="text-muted ms-3">{{ item.refusal_info?.reported_at| date : 'yyyy/M/d H:mm:ss' }}</small>
            </div>
            <pre class="report-confirm-pre mt-2">{{ item.refusal_info?.reason_text }}@if (item.refusal_info?.reason_text === 'その他理由') {: {{ item.refusal_info?.other_reason }} }</pre>
            <div>
              <div class="form-check form-check-inline report-confirm-radio">
                <input class="form-check-input" type="radio" name="radio-{{ item.refusal_info?.refusal_id }}" id="radio-{{ item.refusal_info?.refusal_id }}-1" [value]="0" [(ngModel)]="radioList[$index].selected">
                <label class="form-check-label" for="radio-{{ item.refusal_info?.refusal_id }}-1">
                  未確認
                </label>
                <div class="report-confirm-radio-bg"></div>
              </div>
              <div class="form-check form-check-inline report-confirm-radio">
                <input class="form-check-input" type="radio" name="radio-{{ item.refusal_info?.refusal_id }}" id="radio-{{ item.refusal_info?.refusal_id }}-2" [value]="1" [(ngModel)]="radioList[$index].selected">
                <label class="form-check-label" for="radio-{{ item.refusal_info?.refusal_id }}-2">
                  確認済み
                </label>
                <div class="report-confirm-radio-bg"></div>
              </div>
              <div class="form-check form-check-inline report-confirm-radio">
                <input class="form-check-input" type="radio" name="radio-{{ item.refusal_info?.refusal_id }}" id="radio-{{ item.refusal_info?.refusal_id }}-3" [value]="2" [(ngModel)]="radioList[$index].selected">
                <label class="form-check-label" for="radio-{{ item.refusal_info?.refusal_id }}-3">
                  無視
                </label>
                <div class="report-confirm-radio-bg"></div>
              </div>
            </div>
          </div>
        </div>      
      }


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary border-0 me-2" (click)="activeModal.close('保存する')">キャンセル</button>
    <button type="button" class="btn btn-primary ms-2" (click)="onSubmit()">保存する</button>
  </div>
</form>