import { Component, Input } from '@angular/core';

import { DatePipe, DecimalPipe } from '@angular/common';
import { PublishAdditionalInfo } from '../../../../lib/gen/openapi';

@Component({
  selector: 'app-publish-info-detail-land',
  standalone: true,
  imports: [DecimalPipe, DatePipe],
  templateUrl: './publish-info-detail-land.component.html',
  styleUrl: '../publish-info-detail.component.scss',
})
export class PublishInfoDetailLandComponent {
  @Input()
  additionalInfo?: PublishAdditionalInfo;
}
