import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private apiService = inject(ApiService);

  async login(email: string, password: string) {
    await this.apiService.getCsrfCookie();
    const res = await this.apiService.login(email, password);
    const token = res.result?.token;

    if (!token) {
      throw new Error('login error');
    }

    localStorage.setItem('access_token', token);

    return this.apiService.login(email, password);
  }

  async logout() {
    localStorage.removeItem('access_token');
  }
}
