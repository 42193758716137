import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-publish-info',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './publish-info.component.html',
  styleUrl: './publish-info.component.scss',
})
export class PublishInfoComponent {}
