
<div class="modal-body">
  <div class="p-3 text-center fw-bold fs-3">
    @for (line of message; track $index) {
      {{ line }}
      @if ($index + 1 != message?.length) {
        <br>
      }
    }
  </div>
  <div class="text-center pt-2">
    <button type="button" class="btn btn-danger" (click)="activeModal.close(true)">
      {{ submitBtnText }}
    </button>
  </div>
  <div class="text-center pt-3 pb-3">
    <button type="button" class="btn btn-outline-secondary border-0" (click)="activeModal.close(false)">
      {{ cancelBtnText }}
    </button>
  </div>
</div>
