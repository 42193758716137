<header class="header-wrapper">

  <app-header></app-header>

  <ul ngbNav [activeId]="activeNav()" class="nav nav-underline header-nav">
    @for (link of links; track link) {
      @if (!link.hide) {
        <li [ngbNavItem]="link.fragment">
          <a ngbNavLink [routerLink]="link.fragment">{{ link.title }}</a>
        </li>
      } @else {
        <li [ngbNavItem]="link.fragment" class="invisible">
          <a ngbNavLink [routerLink]="link.fragment">{{ link.title }}</a>
        </li>
      }
    }
  </ul>
</header>

<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
