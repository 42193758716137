import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  GetUserListResponse,
  GetUserDetailResponse,
  GetRefusalInfoListResponse,
  GetRecentRefusalInfoListResponse,
  GetAnnounceListResponse,
  GetRecentAnnounceListResponse,
  GetPublishInfoDetailResponse,
  GetPublishInfoCommentListResponse,
  GetPublishInfoOfferListResponse,
  GetPublishInfoRefusalListResponse,
  UpdateRefusalInfoResponse,
  RefusalInfo,
  GetCommentInfoRefusalListResponse,
  DeletePublishInfoResponse,
  BanUserResponse,
  UnBanUserResponse,
  UserLoginResponse,
} from '../lib/gen/openapi/model/models';
import { lastValueFrom, map } from 'rxjs';
import dummyUserList from '../../assets/dummy/json/dummy_user_list.json';
import dummyUserDetail from '../../assets/dummy/json/dummy_user_detail.json';
import dummyRefusalInfoSummaryList from '../../assets/dummy/json/dummy_refusal_summary_list.json';
import dummyRecentRefusalInfoList from '../../assets/dummy/json/dummy_refusal_recent_list.json';
import dummyAnnounceList from '../../assets/dummy/json/dummy_announce_list.json';
import dummyRecentAnnounceList from '../../assets/dummy/json/dummy_announce_recent_list.json';
import dummyPublishInfoDetail from '../../assets/dummy/json/dummy_publish_info_detail.json';
import dummyCommentList from '../../assets/dummy/json/dummy_comment_list.json';
import dummyOfferList from '../../assets/dummy/json/dummy_offer_list.json';
import dummyPublishInfoRefusalList from '../../assets/dummy/json/dummy_publish_info_refusal_list.json';
import dummyCommentInfoRefusalList from '../../assets/dummy/json/dummy_comment_refusal_list.json';

export abstract class ApiService {
  abstract getCsrfCookie(): Promise<void>;
  abstract login(email: string, password: string): Promise<UserLoginResponse>;
  abstract getUserList(page: number, term?: string): Promise<GetUserListResponse>;
  abstract getUserDetail(id: number): Promise<GetUserDetailResponse>;
  abstract banUser(id: number): Promise<BanUserResponse>;
  abstract unBanUser(id: number): Promise<UnBanUserResponse>;
  abstract getRefusalSummaryList(page: number): Promise<GetRefusalInfoListResponse>;
  abstract getRefusalRecentList(): Promise<GetRecentRefusalInfoListResponse>;
  abstract getAnnounceList(page: number): Promise<GetAnnounceListResponse>;
  abstract getAnnounceRecentList(): Promise<GetRecentAnnounceListResponse>;
  abstract getPublishInfo(publishId: number): Promise<GetPublishInfoDetailResponse>;
  abstract deletePublishInfo(publishId: number): Promise<DeletePublishInfoResponse>;
  abstract getCommentListByPublishId(publishId: number): Promise<GetPublishInfoCommentListResponse>;
  abstract getOfferListByPublishId(publishId: number): Promise<GetPublishInfoOfferListResponse>;
  abstract getRefusalListByPublishId(publishId: number): Promise<GetPublishInfoRefusalListResponse>;
  abstract getCommentRefusalInfoList(commentId: number): Promise<GetCommentInfoRefusalListResponse>;
  abstract updateRefusalInfo(
    id: number,
    refusalInfo: RefusalInfo
  ): Promise<UpdateRefusalInfoResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class ApiWebService extends ApiService {
  private apiUrl = environment.apiUrl;
  private http = inject(HttpClient);

  override getCsrfCookie(): Promise<void> {
    const response = this.http.get<void>(this.apiUrl + '/api/sanctum/csrf-cookie').pipe(
      map((res) => {
        return res;
      })
    );
    return lastValueFrom(response);
  }

  override login(username: string, password: string): Promise<UserLoginResponse> {
    const response = this.http
      .post<UserLoginResponse>(this.apiUrl + '/api/auth/login', { username, password })
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  getUserList(page: number, term?: string): Promise<GetUserListResponse> {
    let params = new HttpParams();
    params = params.append('page', page);
    if (term != null) {
      params = params.append('term', term);
    }
    const response = this.http
      .get<GetUserListResponse>(this.apiUrl + '/api/users', { params: params })
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  getUserDetail(id: number): Promise<GetUserDetailResponse> {
    const response = this.http.get<GetUserDetailResponse>(this.apiUrl + `/api/users/${id}`).pipe(
      map((res) => {
        return res;
      })
    );
    return lastValueFrom(response);
  }

  override banUser(id: number): Promise<BanUserResponse> {
    const response = this.http.post<BanUserResponse>(this.apiUrl + `/api/users/${id}/ban`, {}).pipe(
      map((res) => {
        return res;
      })
    );
    return lastValueFrom(response);
  }

  override unBanUser(id: number): Promise<UnBanUserResponse> {
    const response = this.http
      .post<UnBanUserResponse>(this.apiUrl + `/api/users/${id}/un-ban`, {})
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  override getRefusalSummaryList(page: number): Promise<GetRefusalInfoListResponse> {
    let params = new HttpParams();
    params = params.append('page', page);
    const response = this.http
      .get<GetRefusalInfoListResponse>(this.apiUrl + `/api/refusals`, { params: params })
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  override getRefusalRecentList(): Promise<GetRecentRefusalInfoListResponse> {
    const response = this.http
      .get<GetRecentRefusalInfoListResponse>(this.apiUrl + `/api/refusals/recent`)
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  override getAnnounceList(page: number): Promise<GetAnnounceListResponse> {
    let params = new HttpParams();
    params = params.append('page', page);
    const response = this.http
      .get<GetAnnounceListResponse>(this.apiUrl + `/api/announces`, { params: params })
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  override getAnnounceRecentList(): Promise<GetRecentAnnounceListResponse> {
    const response = this.http
      .get<GetRecentAnnounceListResponse>(this.apiUrl + `/api/announces/recent`)
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  override getPublishInfo(publishId: number): Promise<GetPublishInfoDetailResponse> {
    const response = this.http
      .get<GetPublishInfoDetailResponse>(this.apiUrl + `/api/publish-info/${publishId}`)
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  override deletePublishInfo(publishId: number): Promise<DeletePublishInfoResponse> {
    const response = this.http
      .delete<DeletePublishInfoResponse>(this.apiUrl + `/api/publish-info/${publishId}`)
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  override getCommentListByPublishId(
    publishId: number
  ): Promise<GetPublishInfoCommentListResponse> {
    const response = this.http
      .get<GetPublishInfoCommentListResponse>(
        this.apiUrl + `/api/publish-info/${publishId}/comments`
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  override getOfferListByPublishId(publishId: number): Promise<GetPublishInfoOfferListResponse> {
    const response = this.http
      .get<GetPublishInfoOfferListResponse>(this.apiUrl + `/api/publish-info/${publishId}/offers`)
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  override getRefusalListByPublishId(
    publishId: number
  ): Promise<GetPublishInfoRefusalListResponse> {
    const response = this.http
      .get<GetPublishInfoRefusalListResponse>(
        this.apiUrl + `/api/publish-info/${publishId}/refusals`
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  override getCommentRefusalInfoList(
    commentId: number
  ): Promise<GetCommentInfoRefusalListResponse> {
    const response = this.http
      .get<GetCommentInfoRefusalListResponse>(this.apiUrl + `/api/comments/${commentId}/refusals`)
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }

  override updateRefusalInfo(
    id: number,
    refusalInfo: RefusalInfo
  ): Promise<UpdateRefusalInfoResponse> {
    const response = this.http
      .post<UpdateRefusalInfoResponse>(this.apiUrl + `/api/refusals/${id}`, refusalInfo)
      .pipe(
        map((res) => {
          return res;
        })
      );
    return lastValueFrom(response);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApiMockService extends ApiService {
  override getCsrfCookie(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 100);
    });
  }

  override login(): Promise<UserLoginResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          'status': 200,
          'error_message': '',
          'result': {
            'token': 'dummy_token',
          },
        });
      }, 100);
    });
  }

  getUserList(page: number, term?: string): Promise<GetUserListResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dummyUserList);
      }, 100);
    });
  }

  getUserDetail(id: number): Promise<GetUserDetailResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dummyUserDetail);
      }, 100);
    });
  }

  override banUser(id: number): Promise<BanUserResponse> {
    throw new Error('Not Implemention');
  }

  override unBanUser(id: number): Promise<UnBanUserResponse> {
    throw new Error('Not Implemention');
  }

  override getRefusalSummaryList(page: number): Promise<GetRefusalInfoListResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dummyRefusalInfoSummaryList);
      }, 100);
    });
  }

  override getRefusalRecentList(): Promise<GetRecentRefusalInfoListResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dummyRecentRefusalInfoList);
      }, 100);
    });
  }

  override getAnnounceList(page: number): Promise<GetAnnounceListResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dummyAnnounceList);
      }, 100);
    });
  }

  override getAnnounceRecentList(): Promise<GetRecentAnnounceListResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dummyRecentAnnounceList);
      }, 100);
    });
  }

  override getPublishInfo(): Promise<GetPublishInfoDetailResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dummyPublishInfoDetail);
      }, 100);
    });
  }

  override deletePublishInfo(publishId: number): Promise<DeletePublishInfoResponse> {
    throw new Error('Not implemention');
  }

  override getCommentListByPublishId(
    publishId: number
  ): Promise<GetPublishInfoCommentListResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dummyCommentList);
      }, 100);
    });
  }

  override getOfferListByPublishId(publishId: number): Promise<GetPublishInfoOfferListResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dummyOfferList);
      }, 100);
    });
  }

  override getRefusalListByPublishId(
    publishId: number
  ): Promise<GetPublishInfoRefusalListResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dummyPublishInfoRefusalList);
      }, 100);
    });
  }

  override getCommentRefusalInfoList(
    commentId: number
  ): Promise<GetCommentInfoRefusalListResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dummyCommentInfoRefusalList);
      }, 100);
    });
  }

  override updateRefusalInfo(
    id: number,
    refusalInfo: RefusalInfo
  ): Promise<UpdateRefusalInfoResponse> {
    throw new Error('Not Implemention');
  }
}
