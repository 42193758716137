<h1 class="text-primary mb-4">ユーザー詳細</h1>

@if (resData?.is_ban) {
  <div class="user-suspended">
    <div class="material-symbols-rounded user-suspended__icon" aria-hidden="true">warning</div>
    <div class="user-suspended__text">凍結中のユーザーです</div>
  </div>
}

<div class="card">
  <div class="card-body">
    <div class="user-wrapper">

      <div class="user-icon-wrapper">
        <img src="{{ resData?.user?.avatar_image_url ?? 'assets/images/user-default.svg' }}" alt="プロフィール画像" class="user-icon-image">
        @if (resData?.identification_status_info?.process_status == 2) {
          <img src="yellow-check-circle.png" alt="本人確認済み" class="user-verified-image">
        }
      </div>

      <div class="user-name-wrapper">
        <div class="fs-6 text-muted">ニックネーム</div>
        <div class="fs-2 fw-bold mt-1">{{ resData?.user?.nickname }}</div>
        <div class="fs-6 text-muted mt-3">氏名</div>
        <div class="fs-2 fw-bold mt-1">{{ resData?.user?.sei }} {{ resData?.user?.mei }}</div>
      </div>

      <div class="user-info-wrapper">
        <div class="fs-6 text-muted">電話番号</div>
        <div class="fs-2 fw-bold mt-1">{{ resData?.user?.tel }}</div>
        <div class="fs-6 text-muted mt-3">メールアドレス</div>
        <div class="fs-4 fw-bold mt-1">{{ resData?.user?.email }}</div>
        <div class="fs-6 text-muted mt-3">住所</div>
        @let zipcode = resData?.user?.zipcode;
        @if (zipcode) {
          <div class="fs-4 fw-bold mt-1">&#12306; {{ zipcode | zipcode }}</div>
        }
        @else {
          <div class="fs-4 fw-bold mt-1">未記入</div>
        }
        <div class="fs-4 fw-bold mt-1">{{ resData?.user?.address ?? "未記入" }}</div>
        <div class="fs-6 text-muted mt-3">本人確認</div>
        <div class="fs-4 fw-bold mt-1">{{ resData?.identification_status_info?.comment ?? "未記入" }}</div>
        <div class="fs-6 text-muted mt-3">プロフィール</div>
        <div class="fs-4 fw-bold mt-1">{{ resData?.user?.intro ?? "未記入" }}</div>
      </div>

    </div>
  </div>
</div>


<div class="d-flex justify-content-between align-items-center mt-5">
  <div>
    <button type="button" class="btn btn-secondary" (click)="onBackPage()">前の画面へ戻る</button>
  </div>
  <div>
    @if (resData?.is_ban) {
      <button type="button" class="btn btn-outline-danger btn-outline-bg-white" (click)="onUnBan()">凍結を解除</button>
    }
    @else {
      <button type="button" class="btn btn-danger" (click)="onBan()">アカウント凍結</button>
    }
  </div>
</div>
