import { Component, inject } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  private router = inject(Router);
  private loginService = inject(LoginService);

  onLogout() {
    this.loginService.logout();
    this.router.navigate(['login']);
  }
}
