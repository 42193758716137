{
    "status": 200,
    "error_message": "",
    "result": {
        "publish_info": {
            "publish_id": 12093487,
            "type_info": 0,
            "price": 22500000,
            "zipcode": "599-0000",
            "address": "大阪府堺市東区南町1-9-3,",
            "address_2": "東畑",
            "address_3": "3",
            "built_yyyymm": "1978年7月",
            "building_size": 189.27,
            "land_size": 245.90,
            "size": null,
            "front_road": "公道",
            "floor": null,
            "station_name_ja": "JR海南駅",
            "station_minutes": 67,
            "address_text": "和歌山県海南市",
            "layout_text": "2LDK",
            "owner": {
                "user": {
                    "user_id": 10000000027,
                    "nickname": "猫好き",
                    "sei": "斉藤",
                    "mei": "新",
                    "email": "yukiko.kawaguchi+4@propre-japan.com",
                    "tel": "0467111111",
                    "intro": "変更確認てすと",
                    "avatar_image_url": "dummy/user1.jpg"
                },
                "identification_status_info": {
                    "process_status": 2,
                    "comment": null
                },
                "posts_count": 30,
                "is_ban": false
            },
            "agent": {
                "user": {
                    "user_id": 10000000180,
                    "nickname": "ABC不動産",
                    "sei": null,
                    "mei": null,
                    "email": "yukiko.kawaguchi+140@propre-japan.com",
                    "tel": "0467-33-3333",
                    "intro": "エージェント140の紹介文です。usersテーブル",
                    "avatar_image_url": "dummy/user2.jpg"
                },
                "identification_status_info": {
                    "process_status": 0,
                    "comment": null
                },
                "posts_count": 0,
                "is_ban": false
            },
            "maximumOfferAmount": 38000000,
            "image_url": "dummy/house2.jpg",
            "additional_info": {
                "right": "所有権",
                "delivery_time": "即時",
                "category": "新築",
                "transactions_conditions": "売主",
                "current_conditions": "居住中",
                "building_structure": "木造",
                "ground_floor": 5,
                "basement_floor": 2,
                "has_reform": "有り",
                "reform_yyyymm": "1990年12月",
                "reform_content": "雨漏り補修しました",
                "leak": "有り-修理済み",
                "termite_damage": "有り-駆除済み",
                "has_flaw": "有り",
                "flaw_content": "傾いています",
                "water_supply_and_drainage_damage": "有り-修理済み",
                "has_fire_damage": "有り",
                "fire_damage_content": "1989年に一部小火を起こしました",
                "has_flood_damage": "有り",
                "flood_damage_content": "1989年に床下浸水しました",
                "land_category": "宅地",
                "adjacent_road_situation": "東",
                "road_width": 4,
                "adjacent_road_length": 5,
                "has_incident_accident": "有り",
                "incident_accident_content": "1989年12月車が飛び込んできました",
                "has_asbestos_use_survey_results": "有り",
                "use_asbestos": "有り",
                "inspection": "有り",
                "survey_yyyymmdd": "1990年12月",
                "survey_result": "特に問題ありません",
                "has_seismic_testing_survey": "有り",
                "seismic_testing_survey_result": "特に問題ありません",
                "has_housing_quality_assessment": "有り",
                "housing_quality_assessment_content": "特に問題ありません"
            },
            "refusal_count": 4,
            "un_read_refusal_count": 1
        }
    }
}