<h1 class="text-primary mb-4">ユーザー管理</h1>

<div class="card">
  <div class="card-body p-4">

    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <div>{{ pagination?.total }}</div>
        <div class="ps-1 pe-3">件中</div>
        <div>{{ pagination?.from }}</div>
        <div class="px-1">〜</div>
        <div>{{ pagination?.to }}</div>
        <div class="ps-1">件表示</div>
      </div>
      <div class="user-search-wrapper">
        <div class="user-search-input-wrapper">
          <input class="form-control" type="text" placeholder="ユーザー検索" [ngModel]="term()" (ngModelChange)="term.set($event)">
        </div>
        <div class="user-search-button-wrapper">
          <button type="button" class="btn btn-primary" (click)="onSearch()">検索</button>
        </div>
      </div>
    </div>

    <div class="common-list-wrapper">
      <table class="table common-list-table">
        <thead>
          <tr>
            <th>ニックネーム</th>
            <th>氏名</th>
            <th>メールアドレス</th>
            <th class="text-center">本人確認</th>
            <th class="text-center">投稿</th>
          </tr>
        </thead>
        <tbody>

          @for (item of userList; track $index) {
            <tr>
              <td>
                <a [routerLink]="['/user', item.user.user_id]">{{ item?.user?.nickname ?? "未入力" }}</a>
                <!-- 凍結中の場合 -->
                @if (item.is_ban) {
                  <div class="common-list-suspended">
                    <div class="material-symbols-rounded common-list-suspended__icon" aria-hidden="true">warning</div>
                    <div class="common-list-suspended__text">凍結中</div>
                  </div>
                }
              </td>
              <td>
                @if (item.user.sei != null && item.user.mei != null) {
                  {{ item.user.sei }} {{ item.user.mei }}
                } @else {
                  未入力
                }
              </td>
              <td>{{ item.user.email ?? "未入力" }}</td>
              <td class="text-center">
                @if (item?.identification_status_info?.process_status == 2) {
                  <img src="yellow-check-circle.png" alt="本人確認済み" class="common-list-verified-image">
                }
              </td>
              <td class="text-center">
                @let postsCount = item?.posts_count;
                @if (postsCount != null && postsCount > 0) {
                  {{ postsCount }}
                }
              </td>
            </tr>            
          }
        </tbody>
      </table>
    </div>

    <nav class="pt-2">
      <ngb-pagination [collectionSize]="total" [(page)]="currentPage" [maxSize]="5" aria-label="Default pagination" (pageChange)="onPageChange($event)">
        <ng-template ngbPaginationPrevious>前へ</ng-template>
        <ng-template ngbPaginationNext>次へ</ng-template>
      </ngb-pagination>
    </nav>

  </div>
</div>
