<div class="row">
  <div class="col-4">
    <table class="table table-bordered detail-table-sm">
      <tbody>
        <tr>
          <th>権利</th>
          <td>{{ additionalInfo?.right ?? '-' }}</td>
        </tr>
        <tr>
          <th>引渡し時期</th>
          <td>{{ additionalInfo?.delivery_time ?? '-' }}</td>
        </tr>
        <tr>
          <th>取引態様</th>
          <td>{{ additionalInfo?.transactions_conditions ?? '-' }}</td>
        </tr>
        <tr>
          <th>種別</th>
          <td>{{ additionalInfo?.category ?? '-' }}</td>
        </tr>
        <tr>
          <th>現況</th>
          <td>{{ additionalInfo?.current_conditions ?? '-' }}</td>
        </tr>
        <tr>
          <th>建物構造</th>
          <td>{{ additionalInfo?.building_structure ?? '-' }}</td>
        </tr>
        <tr>
          <th>地上</th>
          <td>{{ additionalInfo?.ground_floor ? additionalInfo?.ground_floor + '階' : '-' }}</td>
        </tr>
        <tr>
          <th>地下</th>
          <td>{{ additionalInfo?.basement_floor ? additionalInfo?.basement_floor + '階' : '-' }}</td>
        </tr>
        <tr>
          <th>地目</th>
          <td>{{ additionalInfo?.land_category ?? '-' }}</td>
        </tr>
        <tr>
          <th>接道状況</th>
          <td>{{ additionalInfo?.adjacent_road_situation ? additionalInfo?.adjacent_road_situation + '側' : '-' }}</td>
        </tr>
        <tr>
          <th>幅員</th>
          <td>{{ additionalInfo?.road_width ? additionalInfo?.road_width + 'm' : '-' }}</td>
        </tr>
        <tr>
          <th>接道長さ</th>
          <td>{{ additionalInfo?.adjacent_road_length ? additionalInfo?.adjacent_road_length + 'm' : '-' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-4">
    <table class="table table-bordered detail-table-sm">
      <tbody>
        <tr>
          <th>リフォーム履歴</th>
          <td>
            @if (additionalInfo?.has_reform != null ) {
              {{ additionalInfo?.has_reform }}&emsp;{{ additionalInfo?.reform_yyyymm }}<br />
              {{ additionalInfo?.reform_content ?? '-' }}
            }
            @else {
              -
            }
          </td>
        </tr>
        <tr>
          <th>雨漏り</th>
          <td>
            {{ additionalInfo?.leak ?? '-' }}
          </td>
        </tr>
        <tr>
          <th>シロアリ被害</th>
          <td>
            {{ additionalInfo?.termite_damage ?? '-' }}
          </td>
        </tr>
        <tr>
          <th>建物の不具合<br />（傾き、腐食など）</th>
          <td>
            @if (additionalInfo?.has_reform != null ) {
              {{ additionalInfo?.has_flaw }}<br />
              {{ additionalInfo?.flaw_content ?? '-' }}
            }
            @else {
              -
            }
          </td>
        </tr>
        <tr>
          <th>給排水設備の<br />故障、漏水</th>
          <td>
            {{ additionalInfo?.water_supply_and_drainage_damage ?? '-' }}
          </td>
        </tr>
        <tr>
          <th>火災被害</th>
          <td>
            @if (additionalInfo?.has_fire_damage != null ) {
              {{ additionalInfo?.has_fire_damage }}<br />
              {{ additionalInfo?.fire_damage_content ?? '-' }}
            }
            @else {
              -
            }
          </td>
        </tr>
        <tr>
          <th>浸水被害</th>
          <td>
            @if (additionalInfo?.has_flood_damage != null ) {
              {{ additionalInfo?.has_flood_damage }}<br />
              {{ additionalInfo?.flood_damage_content ?? '-' }}
            }
            @else {
              -
            }
          </td>
        </tr>
        <tr>
          <th>過去に起きた<br />事件事故</th>
          <td>
            @if (additionalInfo?.has_incident_accident != null ) {
              {{ additionalInfo?.has_incident_accident }}<br />
              {{ additionalInfo?.incident_accident_content ?? '-' }}
            }
            @else {
              -
            }
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-4">
    <table class="table table-bordered detail-table-sm">
      <tbody>
        <tr>
          <th>石錦（アスベスト）<br />使用調査結果の記録</th>
          <td>{{ additionalInfo?.has_asbestos_use_survey_results ?? '-' }}</td>
        </tr>
        <tr>
          <th>アスベスト使用</th>
          <td>{{ additionalInfo?.use_asbestos ?? '-' }}</td>
        </tr>
        <tr>
          <th>建物状況調査<br />（インスペクション）</th>
          <td>
            @if (additionalInfo?.inspection != null ) {
              {{ additionalInfo?.inspection }}&emsp;{{ additionalInfo?.survey_yyyymmdd }}<br />
              {{ additionalInfo?.survey_result ?? '-' }}
            }
            @else {
              -
            }
          </td>
        </tr>
        <tr>
          <th>耐震診断調査</th>
          <td>
            @if (additionalInfo?.has_seismic_testing_survey != null ) {
              {{ additionalInfo?.has_seismic_testing_survey }}<br />
              {{ additionalInfo?.seismic_testing_survey_result ?? '-' }}
            }
            @else {
              -
            }
          </td>
        </tr>
        <tr>
          <th>住宅性能評価</th>
          <td>
            @if (additionalInfo?.has_housing_quality_assessment != null ) {
              {{ additionalInfo?.has_housing_quality_assessment }}<br />
              {{ additionalInfo?.housing_quality_assessment_content ?? '-' }}
            }
            @else {
              -
            }
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
