{
    "status": 200,
    "error_message": "",
    "result": [
        {
            "offer_id": 184,
            "publish_id": 596,
            "deal_status": 3,
            "price": 8800000,
            "delete_flg": null,
            "offer_user": {
                "user": {
                    "user_id": 10000000062,
                    "agent_flg": "1",
                    "nickname": "yuki-test05",
                    "sei": "y",
                    "mei": "i",
                    "email": "yukiko.kawaguchi+5@propre-japan.com",
                    "tel": "123555555",
                    "intro": "てすと\nてすと\nてすと",
                    "avatar_image_url": "https://fufu-dev.s3.ap-northeast-1.amazonaws.com/65f7f25f8f061_5448.png"
                },
                "identification_status_info": {
                    "process_status": 2,
                    "comment": null
                },
                "posts_count": 0,
                "is_ban": false
            }
        },
        {
            "offer_id": 185,
            "publish_id": 596,
            "deal_status": 1,
            "price": 7200000,
            "delete_flg": null,
            "offer_user": {
                "user": {
                    "user_id": 1,
                    "agent_flg": "0",
                    "nickname": "yuki_test",
                    "sei": "川口",
                    "mei": "由紀子",
                    "email": "yukiko.kawaguchi@propre-japan.com",
                    "tel": "123456789",
                    "intro": "",
                    "avatar_image_url": "https://fufu-dev.s3.ap-northeast-1.amazonaws.com/65d5a9705ba8a_3086.jpg"
                },
                "identification_status_info": {
                    "process_status": 2,
                    "comment": null
                },
                "posts_count": 8,
                "is_ban": true
            }
        },
        {
            "offer_id": 186,
            "publish_id": 596,
            "deal_status": 1,
            "price": 9600000,
            "delete_flg": null,
            "offer_user": {
                "user": {
                    "user_id": 10000000019,
                    "agent_flg": "0",
                    "nickname": "yuki_test2へんこう！",
                    "sei": "川口",
                    "mei": "由紀子2",
                    "email": "yukiko.kawaguchi+2@propre-japan.com",
                    "tel": "0311111112",
                    "intro": "へんこうします",
                    "avatar_image_url": "https://fufu-dev.s3.ap-northeast-1.amazonaws.com/65f90ab515e92_4003.png"
                },
                "identification_status_info": {
                    "process_status": 2,
                    "comment": null
                },
                "posts_count": 15,
                "is_ban": false
            }
        }
    ]
}