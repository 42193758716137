import { Component, inject, OnInit, signal } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { GetUserListResponseData, Pagination } from '../../../lib/gen/openapi';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [RouterLink, FormsModule, NgbPaginationModule],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss',
})
export class UserListComponent {
  private userService = inject(UserService);
  pagination?: Pagination;
  userList: GetUserListResponseData[] = [];
  currentPage = 0;
  total = 0;
  readonly term = signal<string | undefined>(undefined);

  /**
   * 更新
   * @param pageNumber
   */
  onPageChange(pageNumber: number) {
    this.update(pageNumber, this.term());
  }

  /**
   * 検索
   */
  onSearch() {
    this.update(this.currentPage, this.term());
  }

  /**
   * 更新
   */
  private update(pageNumber: number, term?: string) {
    this.userService.getUserList(pageNumber, term).then((res) => {
      if (res.result?.user_list != null && res.result?.pagination != null) {
        this.pagination = res.result.pagination;
        this.currentPage = res.result.pagination.currentPage ?? 0;
        this.total = res.result.pagination.total ?? 0;
        this.userList = res.result.user_list;
      }
    });
  }
}
