import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'zipcode',
})
export class ZipcodePipe implements PipeTransform {
  transform(value: string | undefined) {
    if (!value) {
      return '';
    }

    let formattedValue = value.replace(/\D/g, '');
    if (!formattedValue || formattedValue?.length < 4) {
      return formattedValue;
    }

    formattedValue = formattedValue.trim();
    const zipWithoutDash = formattedValue.replace(/-/g, '');

    if (!zipWithoutDash || zipWithoutDash.length < 4) {
      return formattedValue;
    }

    const firstFiveDigits = zipWithoutDash.slice(0, 3);
    const lastFourDigits = zipWithoutDash.length > 3 ? zipWithoutDash.slice(3) : '';
    const lastFourWithDash = lastFourDigits ? '-' + lastFourDigits : '';

    return `${firstFiveDigits}${lastFourWithDash}`;
  }
}
