import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { GetRefusalInfoListResponseData, Pagination } from '../../../lib/gen/openapi';
import { ReportService } from '../../../services/report.service';

@Component({
  selector: 'app-report-list',
  standalone: true,
  imports: [RouterLink, NgbPaginationModule],
  templateUrl: './report-list.component.html',
  styleUrl: './report-list.component.scss',
})
export class ReportListComponent {
  private reportService = inject(ReportService);
  pagination?: Pagination;
  resData: GetRefusalInfoListResponseData[] = [];
  currentPage = 0;
  total = 0;

  /**
   * 更新
   * @param pageNumber
   */
  onPageChange(pageNumber: number) {
    this.update(pageNumber);
  }

  /**
   * 検索
   */
  onSearch() {
    this.update(this.currentPage);
  }

  /**
   * 更新
   */
  private update(pageNumber: number) {
    this.reportService.getReportList(pageNumber).then((res) => {
      if (res.result?.refusal_summary_list != null && res.result?.pagination != null) {
        this.pagination = res.result.pagination;
        this.currentPage = res.result.pagination.currentPage ?? 0;
        this.total = res.result.pagination.total ?? 0;
        this.resData = res.result.refusal_summary_list;
      }
    });
  }
}
