import { Component, inject, Input } from '@angular/core';
import { GetUserDetailResponseData } from '../../../lib/gen/openapi';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompleteModalComponent } from '../../../components/common/complete-modal/complete-modal.component';
import { UserService } from '../../../services/user.service';
import { ConfirmModalComponent } from '../../../components/common/confirm-modal/confirm-modal.component';
import { ZipcodePipe } from '../../../utils/pipes/zip-code.pipe';

@Component({
  selector: 'app-user-detail',
  standalone: true,
  imports: [ZipcodePipe],
  templateUrl: './user-detail.component.html',
  styleUrl: './user-detail.component.scss',
})
export class UserDetailComponent {
  private userService = inject(UserService);
  private location = inject(Location);
  private modalService = inject(NgbModal);

  @Input()
  set id(userId: string) {
    this.loadData(parseInt(userId));
  }

  // コンポーネント変数の定義
  resData?: GetUserDetailResponseData;

  loadData(userId: number) {
    this.userService.getDetail(userId).then((res) => {
      this.resData = res.result;
    });
  }

  /**
   * 前のページに戻る
   */
  onBackPage() {
    this.location.back();
  }

  /**
   * 凍結する
   */
  async onBan() {
    const userId = this.resData?.user.user_id;

    // 確認モーダルを開く
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
      modalDialogClass: 'common-confirm-modal',
    });
    confirmModalRef.componentInstance.message = [
      'このアカウントを凍結します。',
      'よろしいですか？',
    ];
    confirmModalRef.componentInstance.submitBtnText = 'アカウント凍結する';

    const onSubmit = await confirmModalRef.result;
    if (onSubmit) {
      if (userId) {
        // API呼び出し
        await this.userService.ban(userId);
        // 完了モーダルを開く
        const completeModalRef = this.modalService.open(CompleteModalComponent, {
          centered: true,
          backdrop: 'static',
          modalDialogClass: 'common-complete-modal',
        });
        completeModalRef.componentInstance.message = 'アカウントを凍結しました。';
        completeModalRef.componentInstance.closeBtnText = '閉じる';
        await completeModalRef.result;
        this.loadData(userId);
      }
    }
  }

  /**
   * 凍結解除する
   */
  async onUnBan() {
    const userId = this.resData?.user.user_id;

    // 確認モーダルを開く
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
      modalDialogClass: 'common-confirm-modal',
    });
    confirmModalRef.componentInstance.message = [
      'このアカウントの凍結を解除します。',
      'よろしいですか？',
    ];
    confirmModalRef.componentInstance.submitBtnText = '凍結を解除する';

    const onSubmit = await confirmModalRef.result;
    if (onSubmit) {
      if (userId) {
        // API呼び出し
        await this.userService.unBan(userId);
        // 完了モーダルを開く
        const completeModalRef = this.modalService.open(CompleteModalComponent, {
          centered: true,
          backdrop: 'static',
          modalDialogClass: 'common-complete-modal',
        });
        completeModalRef.componentInstance.message = 'アカウントの凍結を解除しました。';
        completeModalRef.componentInstance.closeBtnText = '閉じる';
        await completeModalRef.result;
        this.loadData(userId);
      }
    }
  }
}
