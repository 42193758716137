import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  standalone: true,
  imports: [],
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.scss',
})
export class ConfirmModalComponent {
  activeModal = inject(NgbActiveModal);

  @Input() message?: string[];
  @Input() submitBtnText?: string;
  @Input() cancelBtnText: string = 'キャンセル';
}
